<template>
  <div class="welcome flex flex-col justify-center items-center h-screen bg-gray-50">
    <h1 class="text-3xl font-bold mb-4">OPT表單系統</h1>
    <p class="text-gray-600">聯絡窗口：<a href="mailto:service@accuinbio.com" class="text-blue-600 hover:text-blue-800">service@accuinbio.com</a></p>
    <p class="text-sm text-gray-400 mt-4">&copy; {{ currentYear }} ACCUiN Biotech 安奕生技. All Rights Reserved. v{{ version }}</p>
  </div>
</template>

<script>
import "tailwindcss/dist/tailwind.css";
export default {
  name: "WelcomeView",
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
.welcome {
  min-height: 100vh;
}
</style>

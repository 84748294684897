import Vue from "vue";
import VueRouter from "vue-router";
import Welcome from "../views/WelcomeView.vue";
import AdminView from "../views/AdminView.vue";
import { auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Home", component: Welcome },
  {
    path: "/accuonme/:id",
    name: "ACCUonMe",
    component: () => import("../views/ACCUonMeView.vue"),
  },
  {
    path: "/admin",
    component: AdminView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "Form",
        component: () => import("../views/AdminView/FormView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "paid-form",
        name: "PaidForm",
        component: () => import("../views/AdminView/PaidFormView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "batch-order",
        name: "BatchOrder",
        component: () => import("../views/AdminView/BatchOrderView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "client",
        name: "Client",
        component: () => import("../views/AdminView/ClientView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "form-template",
        name: "FormTemplate",
        component: () => import("../views/AdminView/FormTemplateView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "testing",
        name: "Testing",
        component: () => import("../views/AdminView/TestingView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "bundle",
        name: "Bundle",
        component: () => import("../views/AdminView/BundleView.vue"),
        meta: { requiresAuth: true },
      },
      {
        name: "default",
        path: "Default",
        component: () => import("../views/AdminView/DefaultView.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/templates/kgilife-members",
    name: "KGILifeMembersRiskAssessmentTemplate",
    component: () =>
      import("../views/FormView/KGILifeMembersRiskAssessmentView.vue"),
  },
  {
    path: "/templates/cancer-risk-assessment",
    name: "CancerRiskAssessmentTemplate",
    component: () =>
      import("../views/FormView/CancerRiskAssessmentView.vue"),
  },
  {
    path: "/templates/home-test-risk-assessment",
    name: "HomeTestRiskAssessmentTemplate",
    component: () =>
      import("../views/FormView/HomeTestRiskAssessment.vue"),
  },
  {
    path: "/orders/kgilife-members",
    name: "KGILifeMembersRiskAssessment",
    component: () =>
      import("../views/FormView/KGILifeMembersRiskAssessmentView.vue"),
  },
  {
    path: "/orderResult/kgilife-members",
    name: "KGILifeMembersRiskAssessmentOrderResult",
    component: () =>
      import("../views/OrderCompleteView.vue"),
  },
  {
    path: "/orderResult/chenkang-risk-assessment",
    name: "ChenKangRiskAssessmentOrderResult",
    component: () =>
      import("../views/OrderCompleteView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFoundView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next("/login");
    } else if (requiresAuth && user) {
      next();
    } else {
      next();
    }
  });
});

export default router;

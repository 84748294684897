<!-- 管理頁面主要視圖元件 -->
<template>
  <div class="admin-view">
    <el-container class="admin-container">
      <!-- 頁面標題區塊 -->
      <el-header class="admin-header">
        <div class="header-content">
          <div class="title">
            OPT表單系統管理後台 
            <span v-if="isDev" class="dev-badge">(開發用)</span>
          </div>
          <div class="user-info">
            <el-avatar :size="32" icon="el-icon-user"></el-avatar>
            <span class="username">{{ userName }}</span>
            <el-button 
              size="small"
              type="text"
              class="logout-btn"
              @click="handleLogout"
            >
              <i class="el-icon-switch-button"></i>
              登出
            </el-button>
          </div>
        </div>
      </el-header>

      <el-menu 
        :default-active="$route.path" 
        mode="horizontal" 
        router
        class="admin-menu"
      >
        <!-- 主要功能選單項目 -->
        <el-menu-item index="/admin">QR code表單</el-menu-item>
        <el-menu-item index="/admin/paid-form">付款表單</el-menu-item>
        <el-menu-item index="/admin/batch-order">批次表單訂單</el-menu-item>
        
        <!-- 設定子選單 -->
        <el-submenu index="/admin/setting">
          <template slot="title">設定</template>
          <el-menu-item index="/admin/bundle">套組</el-menu-item>
          <el-menu-item index="/admin/testing">檢測項目</el-menu-item>
          <el-menu-item index="/admin/client">廠商</el-menu-item>
          <el-menu-item index="/admin/form-template">表單範本</el-menu-item>
          <el-menu-item index="/admin/default">轉移資料</el-menu-item>
        </el-submenu>
      </el-menu>

      <el-main class="admin-main">
        <router-view />
      </el-main>

      <!-- 新增頁尾 -->
      <el-footer class="admin-footer">
        <div class="footer-content">
          <span>&copy; {{ currentYear }} ACCUiN Biotech 安奕生技. All Rights Reserved.</span>
          <span class="version-info">Version {{ version }}</span>
          <span class="last-edit">建置時間: {{ buildTime }}</span>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

/**
 * @component AdminView
 * @description 管理頁面主要視圖元件，提供導航選單和子頁面容器
 */
export default {
  name: "AdminView",

  data() {
    return {
      // 從環境變數獲取版本號
      version: process.env.VUE_APP_VERSION || '1.0.0',
      // 判斷是否為開發環境
      isDev: process.env.NODE_ENV === "development",
      userName: '',
      currentYear: new Date().getFullYear(),
      buildTime: process.env.VUE_APP_BUILD_TIME || '未知'
    };
  },

  created() {
    this.initAuthListener();
  },

  methods: {
    initAuthListener() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // 使用 email 作為顯示名稱，如果沒有 displayName 的話
          this.userName = user.displayName || user.email;
        } else {
          // 如果沒有登入，導向登入頁
          this.$router.push('/login');
        }
      });
    },

    handleLogout() {
      this.$confirm('確定要登出嗎?', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          const auth = getAuth();
          await signOut(auth);
          this.$message.success('已登出');
          this.$router.push('/login');
        } catch (error) {
          this.$message.error('登出失敗：' + error.message);
        }
      }).catch(() => {
        this.$message.info('已取消登出');
      });
    }
  }
};
</script>

<style scoped>
.admin-view {
  min-height: 100vh;
  background-color: #f5f7fa;
}

.admin-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #2c4a3e;
  padding: 0 20px;
  height: 60px !important;
}

.admin-menu {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 999;
  margin-bottom: 0;
  background-color: #2c4a3e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  padding: 0 20px;
}

.admin-main {
  margin-top: 120px;
  margin-bottom: 40px;
  min-height: calc(100vh - 160px);
  background-color: #e6f8de;
  padding: 20px;
}

.admin-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #ffffff;
  color: #606266;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  border-top: 1px solid #e4e7ed;
  height: 40px !important;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  font-size: 1.3em;
  font-weight: 600;
  color: #ffffff;
}

.dev-badge {
  background-color: #95d475;
  color: #2c4a3e;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-left: 12px;
  font-weight: 500;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.username {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

.logout-btn {
  color: #e0e0e0;
  transition: all 0.3s ease;
}

.logout-btn:hover {
  color: #95d475;
  transform: scale(1.05);
}

:deep(.el-menu) {
  background-color: #2c4a3e;
  border-bottom: none;
}

:deep(.el-menu-item) {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff !important;
}

:deep(.el-menu-item.is-active) {
  color: #95d475 !important;
  border-bottom: 2px solid #95d475;
  background-color: #1e3329 !important;
}

:deep(.el-menu-item:hover) {
  background-color: #1e3329 !important;
  color: #95d475 !important;
}

:deep(.el-submenu__title) {
  color: #ffffff !important;
}

:deep(.el-submenu__title:hover) {
  background-color: #1e3329 !important;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.version-info, .last-edit {
  color: #909399;
}
</style>
